.checkbox {
  appearance: none;
  background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KICAgIDxyZWN0IHg9IjAuNSIgeT0iMC41IiB3aWR0aD0iMjMiIGhlaWdodD0iMjMiIHJ4PSIzLjUiIHN0cm9rZT0iIzQ5NEI1NCIvPgo8L3N2Zz4K);
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  border-radius: 4px;
  cursor: pointer;
}

.checkbox:disabled {
  pointer-events: none;
  opacity: 0.5;
}

.checkbox:checked {
  background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KICAgIDxyZWN0IHg9IjAuNSIgeT0iMC41IiB3aWR0aD0iMjMiIGhlaWdodD0iMjMiIHJ4PSIzLjUiIHN0cm9rZT0iIzQ5NEI1NCIvPgogICAgPGcgY2xpcC1wYXRoPSJ1cmwoI2NsaXAwXzQ3MDJfMzQ1MDIpIj4KICAgICAgICA8cGF0aCBkPSJNNCAxMi41MTA1TDkuNDc5MSAxOC4wMDAxTDIwIDcuNDg5NTZMMTguNDg5NiA2TDkuNDc5MSAxNUw1LjQ4OTUzIDExLjAxMDVMNCAxMi41MTA1WiIgZmlsbD0iI0ZGOTQwMCIvPgogICAgPC9nPgogICAgPGRlZnM+CiAgICAgICAgPGNsaXBQYXRoIGlkPSJjbGlwMF80NzAyXzM0NTAyIj4KICAgICAgICAgICAgPHJlY3Qgd2lkdGg9IjE2IiBoZWlnaHQ9IjE2IiBmaWxsPSJ3aGl0ZSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoNCA0KSIvPgogICAgICAgIDwvY2xpcFBhdGg+CiAgICA8L2RlZnM+Cjwvc3ZnPgo=);
}
