.select {
  background-repeat: no-repeat;
  width: 50px;
  appearance: none;
  padding-right: 19px;
  padding-left: 0.75rem;
  background-size: 16px 12px;
  border: none;
  background-position: right 0.25rem center;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  cursor: pointer;
  font-family: 'TwemojiCountryFlags', 'Inter', sans-serif;
}
